import { createEventCapture } from "next/common";

const ClickEvent = createEventCapture();

customElements.define(
    "rm-cta",
    class extends HTMLElement {
        connectedCallback() {
            this.addEventListener("click", (e) => {
                ClickEvent.capture({
                    type: "cta__selection",
                    data: {
                        href: this.querySelector("a").getAttribute("href"),
                        source: this.querySelector("a").dataset.clickSource
                    },
                });
            });
        }
    },
);
